<template>
  <div class="card sixteen wide column mt-3 mb-0 message-card">
    <div class="content" style="padding: 6px">
      <div
        class="floated meta"
        :class="{ 'text-right': user.id.toString() !== message.user._id }"
        style="font-size: 12px"
      >
        {{ formatDate(message.createdAt) }}
      </div>
      <img
        class="floated mini ui image"
        :class="[ user.id.toString() === message.user._id ? 'right' : 'left' ]"
        :src="getAvatar(message.user.avatar)"
        style="height: 35px; width: 35px; border-radius: 17px; object-fit: cover;"
      >
      <div
        class="header"
        :class="{ 'text-right': user.id.toString() === message.user._id }"
        style="font-size: 15px"
      >
        {{ message.user.fullname }}
      </div>
      <div
        class="meta"
        :class="{ 'text-right': user.id.toString() === message.user._id }"
      >
        {{ $t(message.user.residentialSpecs ?? "") }}
      </div>
      <div
        class="description"
        :class="{ 'text-right': user.id.toString() === message.user._id }"
        style="font-size: 15px; white-space: pre-wrap;"
      >
        {{ message.message }}
        <div
          v-if="message.attachments.length > 0"
          class="row m-0 mt-3"
          style="margin-left: 10px; border-top: 1px solid #F0F0F0; padding-top: 15px"
        >
          <div
            v-for="(attachment, index) in message.attachments"
            :key="index"
            class="col-md-1 text-left"
          >
            <a :href="attachment" target="_blank">
              <i
                v-if="['png', 'jpg', 'jpeg'].includes(getFileFormat(attachment))"
                class="file image outline icon icon-attachment"
              ></i>
              <i
                v-if="['pdf'].includes(getFileFormat(attachment))"
                class="file pdf outline icon icon-attachment"
              ></i>
              <div class="mt-1">
                <span style="font-size: 13px">{{ getFileName(attachment) }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales'
import { mapGetters } from 'vuex'
import helpers from '../../utils/helpers'

export default {
  name: 'MessageDetailCard',
  props: {
    message: Object
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  methods: {
    formatDate (date) {
      moment.locale('es')
      return moment(date).fromNow()
    },

    getFileFormat (fileName) {
      const fileNameSplit = fileName.split('.')
      return fileNameSplit[fileNameSplit.length - 1].toLowerCase()
    },

    getFileName (fileUrl) {
      const fileNameSplit = fileUrl.split('/')
      return fileNameSplit[fileNameSplit.length - 1].substr(0, 6) + '...' + this.getFileFormat(fileUrl)
    },

    getAvatar (avatar) {
      return helpers.getAvatar(avatar)
    }
  }
}
</script>

<style scoped>
  .message-card {
    padding: 4px;
    padding-bottom: 7px
  }

  .img-attachment {
    width: 70px;
    height: 70px;
    border-radius: 7px;
    object-fit: cover
  }

  .icon-attachment {
    font-size: 50px;
    border-radius: 7px
  }
</style>
