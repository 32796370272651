<template>
  <div style="position: relative; height: 100%">
    <div class="row-msgsubject-and-editbutton">
      <span class="general-blue-color" style="font-size: 17px; font-weight: bold">
        {{ truncateText(conversationActive.subject, msgDetailSubjectMaxLengthToShow) }}
      </span>
      <!-- class="delete" -->
      <!-- v-if="isGroupConversation" -->
      <Button
        :appearance="classBtnObject"
        :text="$t('messages.members')"
        color="primary"
        @clicked="editConversation"
      />
    </div>
    <div class="my-3"></div>
    <div id="messages-container">
      <div class="ui cards ui grid pb-2">

        <!-- <MessageDetailCard :message="active" /> -->

        <MessageDetailCard
          v-for="message in active"
          :key="message.createdAt"
          :message="message"
        />
      </div>
    </div>

    <div class="row w-100 message-input">
      <div class="col-md-12 ui input">
        <textarea
          v-model="textMessage"
          :placeholder="$t('messages.message')"
          style="resize: none; height: 70px"
          class="w-100"
          @keyup.enter="sendMessageKeyPress"
        ></textarea>
        <!-- <span class="textarea" role="textbox" contenteditable></span> -->
        <button
        @click="sendMessage"
        :class="{ 'loading': (isLoading === true) }"
        class="circular ui icon button button-send"
          style="margin-top: auto; margin-bottom: auto"
        >
          <i
            class="arrow right icon inverted"
            :class="{ 'msg-color': (isLoading === true) }"
          ></i>
        </button>
      </div>
      <div class="col-md-12" style="margin-top: 10px">
        <FileUploader
          :maxNumFiles="3"
          :maxFileSizeInMB="10"
          :allowedFileTypes="['pdf', 'jpg', 'png', 'jpeg']"
          :sizeOfButton="{ 'height': '30px', 'width': '140px' }"
          :modelValue="files"
          @update-modelValue="getFiles"
        />
      </div>
    </div>

    <!-- <EditConversationModal
      :show="isEditingConversationModal"
      :editConversation="conversationActive"
      @close="closeModal"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FileUploader from '../../components/form/FileUploader'
import { createMessage } from '../../api/messaging'
import MessageDetailCard from './MessageDetailCard'
import Button from '@/components/form/Button'
// import EditConversationModal from '@/components/modals/EditConversationModal.vue'
import helpers from '../../utils/helpers'

export default {
  name: 'MessageDetail',
  components: { MessageDetailCard, FileUploader, Button },
  // EditConversationModal
  // },
  data () {
    return {
      isLoading: false,
      textMessage: '',
      messages: [],
      files: [],
      // isEditingConversation: false,
      msgDetailSubjectMaxLengthToShow: 70
    }
  },
  mixins: [{
    methods: {
      truncateText: helpers.truncateText
    }
  }],
  computed: {
    ...mapGetters('user', ['userMessageId']),
    ...mapGetters('messaging', ['active', 'conversationActive']),
    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
        // padding: '7px 2.4rem !important'
      }
    }
    // isGroupConversation () {
    //   return this.conversationActive.participants.length > 2
    // },
    // isEditingConversationModal () {
    //   return this.isEditingConversation
    // }
  },
  watch: {
    active () {
      this.textMessage = ''
      this.files = []
      // console.log('active changed ->', this.active)
      // console.log('conversationActive ->', this.conversationActive)
      // console.log('this.conversationActive.uid ->', this.conversationActive.uid)
      setTimeout(() => {
        const container = this.$el.querySelector('#messages-container')
        container.scrollTop = container.scrollHeight
      }, 500)
    }
  },
  methods: {
    getFiles (data) {
      this.files = data
    },

    async sendMessage () {
      if (this.textMessage) {
        this.isLoading = true

        // let attachments
        // if (this.files.length > 0) {
        //   const formData = new FormData()
        //   for (const file of this.files) {
        //     formData.append('files', file)
        //   }
        //   try {
        //     const { data } = await uploadFiles(formData)
        //     attachments = data
        //   } catch (e) {
        //     attachments = []
        //   }
        // }
        const attachments = await this.sendMessageAPI()
        this.$store.dispatch('messaging/pushMessage', {
          conversationId: this.conversationActive.uid,
          data: {
            message: this.textMessage,
            attachments
          }
        }).then(() => {})
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: this.$t('errors.error'),
              text: this.$t('errors.messages.messageNotSent'),
              icon: 'error'
            }).then(() => {
              this.$router.go()
            })
          })
        // this.$socket.emit('message:reply', {
        //   conversationId: this.active.uid,
        //   data: {
        //     text: this.textMessage,
        //     attachments
        //   }
        // })
        this.textMessage = ''
        this.files = []
        this.isLoading = false
      }
    },
    async sendMessageAPI () {
      const formData = new FormData()
      formData.append('message', this.textMessage)
      formData.append('to', this.conversationActive.participants.join(','))
      if (this.files.length > 0) {
        for (const file of this.files) {
          formData.append('files', file)
        }
      }
      try {
        const { data } = await createMessage(formData)
        return data.files
      } catch (e) {
        return []
      }
    },
    sendMessageKeyPress (e) {
      if (e.ctrlKey) {
        this.textMessage = this.textMessage + '\n'
      } else {
        this.sendMessage()
      }
    },

    loadFiles (event) {
      const count = event.target.files.length
      if ((this.files.length + count) > 3) {
        this.$swal(this.$t('errors.messages.maxAttachements'), '', 'error')
        return false
      }
      this.files = [
        ...this.files,
        ...event.target.files
      ]
    },

    removeFile (index) {
      this.files.splice(index, 1)
    },

    launchFilePicker () {
      this.$refs.file.click()
    },

    editConversation () {
      // this.isEditingConversation = true
      this.$router.push({
        path: '/messaging/edit'
      })
    }
    // closeModal () {
    //   this.isEditingConversation = false
    //   this.modalData = {}
    // }
  }
}
</script>

<style scoped>
  .message-input {
    position: absolute;
    bottom: 0px;
  }

  #messages-container {
    overflow:hidden;
    overflow-y: auto;
    height: 360px;
    padding: 10px;
    padding-left: 1px;
    border-bottom: 1px solid #F0F0F0;
  }

  .button-send {
    margin-right: -9px;
    margin-left: 10px;
    cursor: pointer;
    background-color: var(--messaging-color);
  }

  .button-send:hover {
    opacity: 0.8;
  }

  .textarea {
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    padding: 1px 6px;
  }

  .textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    min-height: 40px;
    line-height: 20px;
  }

  .textarea[contenteditable]:empty::before {
    content: "Placeholder still possible";
    color: gray;
  }

  .delete, .save {
    height: 25px !important;
    text-transform: none !important;
    letter-spacing: normal !important;
    border-radius: 5px !important;
    transition: background-color .5s ease !important;
    border: none !important;
    width: 10rem !important;
    /*font-weight: bold;*/
    font-family: inherit;
  }

  .delete {
    margin-right: 20px !important;
  }

  .row-msgsubject-and-editbutton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 29px;
    align-items: center;
  }
</style>
