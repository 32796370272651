<template>
  <span class="mb-5" style="font-size: 17px; font-weight: bold; margin-left: 20px">
    {{  $t('messages.new_message') }}
    <!-- <span v-if="isMassive">masivo</span>
    <span v-else>personalizado</span> -->
  </span>
  <div class="my-4"></div>
  <form
    class="ui form fields"
    novalidate
    :class="{ 'blue medium double loading':isLoading }"
    style="margin-left: 20px; margin-right: 20px"
    autocomplete="off"
  >
    <div class="row">
      <div class="col ui input">
        <input type="text" v-model="subject" :placeholder="$t('messages.enter_subject')"  >
      </div>
    </div>
    <!-- <div class="row">

      <div class="col-md-3 pt-3">{{  $t('messages.send_to_all') }}</div>
      <div class="col ui input pt-3">
        <span :style="{ 'font-weight': !sendToAll ? 'bold' : 'normal' }">{{  $t('no') }}</span>
        <div
          class="ui toggle checkbox text-center"
          style="margin-left: 10px; margin-right: 16px; padding-top: 0;"
        >
          <input type="checkbox" v-model="sendToAll">
          <label style="height: 0px; width:0px"></label>
        </div>
        <span :style="{ 'font-weight': sendToAll ? 'bold' : 'normal' }">{{  $t('yes') }}</span>
      </div>
    </div> -->
    <!-- class="row mt-2" -->
    <div class="row mt-2">
      <!-- class="col ui input" id="message-form-residents" -->
      <div class="col ui input" id="message-form-residents">
        <!-- <Select
          v-show="isMassive && !sendToAll"
          v-model="residentMassiveSelected"
          :options="residents"
          :multiple="true"
          :search="true"
          :placeholder="$t('messages.choose_residents')"
        /> -->
        <!-- OLD CODE -->
        <!-- <vue-multi-select
          v-show="isMassive && !sendToAll"
          :key="componentKey"
          :value="myResidents"
          :options="residents"
          :required="false"
           :placeholder="$t('messages.choose_residents')"
          :getOptionDescription="(option) => option.description"
          option-key="id"
          option-label="description"
          :max-results="7"
          :classes='{
            active: "active",
            wrapper: "multi-select-wrapper",
            searchWrapper: "search-wrapper",
            searchInput: "search-input",
            pill: "itemPill",
            dropdown: "dropdown"
        }'
          ></vue-multi-select> -->
        <!-- OLD CODE -->
        <multiselect
          v-show="!sendToAll"
          searchable
          v-model="myResidents"
          :options="residents"
          :close-on-select="true"
          :clear-on-select="true"
           :placeholder="$t('messages.choose_residents')"
          label="description"
          track-by="description"
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
          :custom-label="residentLabel"
          hideSelected
          :showNoResults="false"
        >
        </multiselect>
        <!-- <Select
          v-show="!isMassive"
          v-model="residentSelected"
          :options="residents"
          :search="true"
          :multiple="isMassive"
          placeholder="Selecciona el residente"
        /> -->
      </div>
    </div>
    <div class="row mt-2">
      <div class="col ui input">
        <textarea v-model="message"   :placeholder="$t('messages.enter_message')"></textarea>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-9 pt-2">
        <FileUploader
          :maxNumFiles="3"
          :maxFileSizeInMB="10"
          :allowedFileTypes="['pdf', 'jpg', 'png', 'jpeg']"
          :sizeOfButton="{'height': '26px', 'width': '130px'}"
          :modelValue="files"
          @update-modelValue="getFiles"
        />
      </div>
      <div class="col-md-3 text-right" style="padding-right: 7px">
        <Button
          v-if="!isLoading"
          color="message"
          :text="$t('send')"
          class="d-inline"
          @clicked="sendMessage"
        />
      </div>
    </div>
  </form>
</template>

<script>
// import VueMultiSelect from 'vue-simple-multi-select'
import Button from '../../components/form/Button'
// import Select from '../../components/form/Select'
import { mapGetters, mapActions } from 'vuex'
import FileUploader from '../../components/form/FileUploader'
import helpers from '@/utils/helpers'
import { createMessage } from '../../api/messaging'
// import Multiselect from 'vue-multiselect'
import Multiselect from 'vue-multiselect'
// import { RolesEnum } from '../../utils/roles.enum'
import { RolesEnum } from '@/utils/roles.enum.js'

export default {
  name: 'MessageForm',
  components: {
    Button,
    // Select,
    FileUploader,
    Multiselect
  },
  // VueMultiSelect
  sockets: {
    connect () {
      console.log('socket to notification channel connected')
    }
  },
  props: {
    isMassive: {
      type: Boolean,
      defaulf: true
    },
    title: { type: String }
  },
  data () {
    return {
      isLoading: false,
      subject: this.title ?? '',
      // sendToAll: !(this.residentialMembersToMessage.length > 0),
      sendToAll: false,
      residentSelected: null,
      residentMassiveSelected: null,
      // myResidents: this.residentialMembersToMessage.length > 0 ? this.residentialMembersToMessage : null,
      myResidents: [],
      message: '',
      toMassive: [],
      files: [],
      componentKey: 0,
      selectedMUL: null,
      optionsMUL: ['list', 'of', 'options']
      // residentsDict: {}
    }
  },
  watch: {
    myResidents (value) {
      // console.log(value)
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'residentialMembersDictionary']),
    ...mapGetters('messaging', ['residentialMembersToMessage']),
    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    residentsFromStore () {
      return this.$store.getters['user/residents']
    },
    residents () {
      // const residents = this.$store.getters['user/residents']
      // this.residentsDict = {}
      return this.residentsFromStore
        .filter(resident => resident.id !== this.user.id)
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map(x => {
          const specs = `${x.building} - ${x.apartment}`
          // console.log('PRINTING X USER -->', x)
          return {
            id: x.id,
            description: `${x.firstName} ${x.lastName}, ${specs}`,
            avatar: helpers.getAvatar(x.avatar)
          }
        })
    },
    residentsDict () {
      return this.$store.getters['user/residentsDictionary']
    }
  },
  created () {
    this.loadResidents()
    this.loadAdminsInfo()
    // setTimeout(() => {
    //   document.getElementsByTagName('select')[0].style.display = 'none'
    // }, 1000)
  },
  updated () {
    this.loadResidents()
    this.loadAdminsInfo()
  },
  mounted () {
    if (this.residentialMembersToMessage.length > 0) {
      this.myResidents = this.residentialMembersToMessage.map(x => {
        const specs = `${x.building} - ${x.apartment}`
        return {
          id: x.id,
          description: `${x.firstName} ${x.lastName}, ${specs}`,
          avatar: helpers.getAvatar(x.avatar)
        }
      })
      this.sendToAll = false
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    loadResidents () {
      if (this.residents.length === 0) {
        this.$store.dispatch('user/loadResidents', this.residential.residentialId)
      }
    },
    loadAdminsInfo () {
      const residentiaMembersQueryData = {
        residentialId: this.residential.residentialId,
        membersRoles: [RolesEnum.ADMINISTRATOR]
      }
      this.setLoading(true)
      this.$store.dispatch('user/loadResidentialMembers', residentiaMembersQueryData)
      // console.log('admin info when modal mounted', this.residentialMembers)
      // console.log('residentialDict info when modal mounted', this.residentsDict)
      this.setLoading(false)
    },

    residentLabel ({ id, description, avatar }) {
      return description
    },

    async sendMessage () {
      if (!this.isMessageValid()) {
        return
      }

      this.isLoading = true

      let participants
      if (this.sendToAll) {
        participants = this.residents.map(x => this.getInfoParticipant(x.id))
      } else {
        // participants = this.residentMassiveSelected.map(x => this.getInfoParticipant(x.value))
        participants = this.getSelectedResidents()
      }

      const attachments = await this.addAttachmentsToMessage(participants.map(x => x.id))
      this.$store.dispatch('messaging/createConversation', {
        subject: this.subject,
        message: this.message,
        residentialId: this.residential.residentialId,
        participants,
        attachments
      }).then((response) => {
        // console.log('Conversation Created', response)
        this.isLoading = false
        this.$swal({
          title: this.$t('messages.messageSent'),
          showCancelButton: false,
          icon: 'success',
          allowOutsideClick: false
        }).then(async (result) => {
          const messages = this.$store.getters['messaging/messages']
          this.$store.dispatch('messaging/getConversationDetail', response.uid)
          this.$store.dispatch('messaging/setConversationActive', messages.find(msg => msg.uid === response.uid))
          this.$router.push({ path: '/messaging/view' })
          if (result.isConfirmed) {
            this.clearForm()
            this.$store.dispatch('messaging/setResidentialMembersToMessage', [])
          }
        })
      }).catch((error) => {
        console.log('Fail on at creating conversation', error)
        this.isLoading = false
        this.$swal({
          title: this.$t('errors.messages.messageNotSent2'),
          showCancelButton: false,
          icon: 'error',
          allowOutsideClick: false
        })
      })
    },

    getInfoParticipant (userId) {
      // const residents = this.$store.getters['user/residents']
      // const resident = residents.find(x => x.id === userId)
      const resident = this.residentsDict[userId]
      return {
        id: userId,
        firstName: resident.firstName,
        lastName: resident.lastName,
        avatar: resident.avatar,
        specs: resident.role.id === 1 ? 'ADMIN' : `${resident.building} - ${resident.apartment}`
      }
    },

    getFiles (data) {
      this.files = data
    },

    async addAttachmentsToMessage (participants) {
      const formData = new FormData()
      formData.append('message', this.message)
      formData.append('to', participants.join(','))
      if (this.files.length > 0) {
        for (const file of this.files) {
          formData.append('files', file)
        }
      }
      try {
        const { data } = await createMessage(formData)
        return data.files
      } catch (e) {
        return []
      }
    },

    isMessageValid () {
      if (!this.subject || !this.message) {
        this.$swal(this.$t('errors.messages.messageBody'), '', 'warning')
        return false
      }

      // if (this.isMassive) {
      //   // const residentsSelected = this.getSelectedResidents()
      //   // const residentsSelected = Boolean(this.myResidents ? this.myResidents.length : 0)
      //   const residentsSelected = this.myResidents.length > 0

      //   if (!this.sendToAll && !residentsSelected) {
      //     this.$swal(this.$t('errors.messages.chooseResident'), '', 'warning')
      //     return false
      //   }
      // } else {
      //   if (!this.residentSelected) {
      //     this.$swal(this.$t('errors.messages.chooseResident'), '', 'warning')
      //     return false
      //   }
      // }

      if (this.myResidents?.length > 0) {
      } else {
        this.myResidents = [this.myResidents]
      }

      if (!this.myResidents.length && !this.sendToAll) {
        this.$swal(this.$t('errors.messages.chooseResident'), '', 'warning')
        return false
      }

      return true
    },

    getSelectedResidents () {
      // const div = document.getElementById('message-form-residents')
      // const options = div.getElementsByTagName('select')[0].options
      // const residentsSelected = Array.from(options).map(option => option.value)
      // const residents = this.$store.getters['user/residents']
      const residentsSelected = Array.from(this.myResidents).map((option) => {
        const residentId = option.id
        // const resident = this.$store.getters['user/residents'].find(x => x.id.toString() === residentId)
        const resident = this.residentsDict[residentId]
        return {
          id: residentId,
          firstName: resident.firstName,
          lastName: resident.lastName,
          avatar: resident.avatar,
          specs: resident.role.id === 1 ? 'ADMIN' : `${resident.building} - ${resident.apartment}`
        }
      })
      return residentsSelected
    },

    clearForm () {
      this.subject = ''
      this.message = ''
      this.myResidents = []
      this.sendToAll = true
      this.$router.push({
        path: '/messaging',
        replace: true,
        params: { isMassive: true, isNew: true }
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
  /* .multi-select-wrapper {
    display: block;
    font-size: 16px;
    width: 100%;
    border: 0px !important;
  } */
  /* .search-input {
    color: black;
  }
  .itemPill {
    background-color: #f1f5f8;
    border: 1px solid #b8c2cc;
    border-radius: 0.25em;
    line-height: 1.2;
    padding: 0.15em;
    padding-left: 10px !important;
    padding-right: 10px !important;
  } */
  /* .multiselect__option--highlight {
    background: #436f98 !important;
    outline: none;
    color: white;
  } */

  /* .multiselect__option--highlight::after {
    content: attr(data-select);
    background: #436f98 !important;
    color: white;
  } */

  /* ----------------------------------------- multiselect style --------------------------------------- */
  .multiselect__tag-icon::after {
    content: "×";
    color: #8C98A1 !important;
    font-size: 14px;
  }

  .multiselect__option--highlight {
    background: #DAE1E7 !important;
    outline: none;
    color: #606F7B !important;
  }

  .multiselect__option--highlight::after {
    content: attr(data-select);
    background: #DAE1E7 !important;
    color: #606F7B !important;
  }
</style>
