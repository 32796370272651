<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start w-100 h-100 view-container">
    <div class="mt-3">
      <div class="row">
        <div class="col-md-3">
          <MessageList />
        </div>
        <div class="col-md-9">
          <div class="message-container">
            <div v-if="residentialMembersToMessage.length!== 0 || !conversationActive">
            <!-- <div v-show="active === null"> -->
              <MessageForm
                :title= this.$route.params.title
              />
              <!-- :isMassive="sendingMassiveMessage" -->
              <!-- :residentialMembersToMessage="residentialMembersToMessage" -->
            </div>
            <!-- <MessageDetail v-if="active"/> -->
            <!-- && residentialMembersToMessage.length === 0 && !comingFromEditMessaging -->
            <MessageDetail v-if="conversationActive && (residentialMembersToMessage.length === 0 || !comingFromEditMessaging)"/>
            <!-- <MessageDetail v-show="residentialMembersToMessage.length === 0"/> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // mapActions,
  mapGetters
} from 'vuex'
import HeaderResidential from '@/components/ui/HeaderResidential'
import MessageList from './MessageList'
import MessageForm from './MessageForm'
import MessageDetail from './MessageDetail'
// import { markMessageAsSeen } from '../../api/messaging'

export default {
  name: 'Messaging',
  components: { HeaderResidential, MessageList, MessageForm, MessageDetail },
  props: {
    isNew: Boolean,
    isMassive: Boolean,
    title: String
  },
  data () {
    return {
      sendingMassiveMessage: this.isMassive
    }
  },
  mounted () {
    if (this.$route.params.title && this.$route.params.title !== '' && this.$route.params.title !== undefined) {
      const title = this.$route.params.title.split(' ')
      const messages = this.$store.getters['messaging/messages']
      const conversation = messages.filter((message) => message.subject.includes(title[0]) && message.subject.includes(title[1]))
      if (conversation.length > 0) {
        const conversationId = conversation[0].uid
        this.$store.dispatch('messaging/getConversationDetail', conversationId)
        this.$store.dispatch('messaging/setConversationActive', messages.find(msg => msg.uid === conversationId))
        this.$store.dispatch('messaging/setResidentialMembersToMessage', [])
        this.$router.push({ path: '/messaging/view' })
      }
    }
  },
  beforeUnmount () {
    // this.$store.commit('messaging/SET_RESIDENTIAL_MEMBERS_TO_MESSAGE', [])
    if (!['/residents', '/message/edit'].includes(this.toRoute)) {
      this.$store.dispatch('messaging/setResidentialMembersToMessage', [])
    }
    if (!['/messaging/edit'].includes(this.toRoute)) {
      this.$store.dispatch('messaging/setConversationActive', null)
    }
    // this.setLoading(false)
  },
  computed: {
    ...mapGetters('messaging', ['active']),
    ...mapGetters('messaging', ['conversationActive']),
    // ...mapGetters('messaging', ['residentialMembersToMessage']),
    ...mapGetters('routes', ['fromRoute', 'toRoute']),
    residentialMembersToMessage () {
      return this.$store.getters['messaging/residentialMembersToMessage']
    },
    comingFromEditMessaging () {
      return this.fromRoute === '/messaging/edit'
    },
    residential () {
      return this.$store.getters['residential/activeResidential']
    }
  }
}
</script>

<style scoped>
  .message-container {
    background-color: white;
    width: 96%;
    height: 102%;
    padding: 20px;
    padding-top: 10px;
  }
</style>
